import * as Sentry from '@sentry/nextjs';

export default class ErrorsService {
  public setUser(userId: string, email: string | null): void {
    Sentry.setUser({ id: userId, email: email ?? undefined });
  }

  public resetUser(): void {
    Sentry.configureScope((scope) => scope.setUser(null));
  }

  // this is always called from the useErrors hook
  public report(err: any): void {
    Sentry.captureException(err);
  }
}
