import { isLocale, Locale, locales } from '@koolumbus/shared/config';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/it';
import { useRouter } from 'next/router';
import { getCommonTxs, TxCommon } from '../lib/intl';

type Txs<D> = { en: D; it: D; es?: D };

type UseIntlOut = {
  lang: Locale;
  txc: TxCommon;
  setLang: (lang: Locale) => void;
};

export function useIntl(): UseIntlOut;
export function useIntl<TxsD>(txs: Txs<TxsD>): UseIntlOut & { txs: TxsD };
export function useIntl<TxsD>(txs?: Txs<TxsD>) {
  const router = useRouter();

  const setLang = (lang: Locale) => {
    if (router.locale !== lang && locales.includes(lang)) {
      dayjs.locale(lang);
      document.cookie = `firebase-language-override=${lang}`;
      document.cookie = `NEXT_LOCALE=${lang}`;
      router.replace(router.pathname, router.asPath, { locale: lang });
    }
  };

  const locale: Locale = router.locale && isLocale(router.locale) ? router.locale : 'en';

  return {
    lang: locale,
    txc: getCommonTxs(locale),
    setLang,
    txs: txs ? txs[locale] : undefined,
  };
}
