import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik';
import set from 'lodash.set';
import { z } from 'zod';

export type FormSubmitAction = FormikHandlers['handleSubmit'];
export type FormChangeAction = FormikHandlers['handleChange'];
export type FormBlurAction = FormikHandlers['handleBlur'];
export type FormSetFieldValueAction<Values> = <K extends keyof Values>(
  key: K,
  value: Values[K],
) => void;
export type FormSetValuesAction<Values> = FormikHelpers<Values>['setValues'];
export type FormTouched<Values> = FormikTouched<Values>;
export type FormSetFieldTouchedAction<Values> = <K extends keyof Values>(
  key: K,
  touched: boolean,
) => void;
export type FormErrors<Values> = FormikErrors<Values>;

export type UseFormOptions<Values> = {
  onSubmit: (values: Values) => void;
  initialValues?: Partial<Values>;
};

function getFormikErrors<Values>(zerr: z.ZodError): FormikErrors<Values> {
  const errors: FormikErrors<Values> = {};

  for (const err of zerr.errors) {
    set(errors, err.path, err.message);
  }

  return errors;
}

export function formValidate<Values>(
  schema: z.ZodObject<any>,
  additionalValidate?: (values: Values, errors: FormikErrors<Values>) => void,
): (values: Values) => FormikErrors<Values> {
  return (values: Values) => {
    let errors: FormikErrors<Values> = {};

    try {
      schema.passthrough().parse(values);
    } catch (err) {
      errors = getFormikErrors(err as z.ZodError);
    }

    if (additionalValidate) {
      additionalValidate(values, errors);
    }

    return errors;
  };
}
