import { config } from '@koolumbus/web/config';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const fbConfig = {
  development: {
    apiKey: 'AIzaSyCtuKBbg9umqOq74j0iQwsuAGeq1_lNCPw',
    authDomain: 'pms-new.firebaseapp.com',
    databaseURL: 'https://pms-new.firebaseio.com',
    projectId: 'pms-new',
    storageBucket: 'pms-new.appspot.com',
    messagingSenderId: '1015360352867',
    appId: '1:1015360352867:web:dc66d31d176310410cfe56',
  },
  staging: {
    apiKey: 'AIzaSyDQw7yzvikD_mgWZ5ZGTdu58QLrDDYHcPA',
    authDomain: 'pms-stage.firebaseapp.com',
    databaseURL: 'https://pms-stage.firebaseio.com',
    projectId: 'pms-stage',
    storageBucket: 'pms-stage.appspot.com',
    messagingSenderId: '487688363366',
    appId: '1:487688363366:web:ced2ddc8c9516005ff3ca7',
  },
  production: {
    apiKey: 'AIzaSyCvaJlP1N9aFmZ6mkEdlXEbq3PaVl7KqN4',
    authDomain: 'pms-prod-271508.firebaseapp.com',
    databaseURL: 'https://pms-prod-271508.firebaseio.com',
    projectId: 'pms-prod-271508',
    storageBucket: 'pms-prod-271508.appspot.com',
    messagingSenderId: '887708608965',
    appId: '1:887708608965:web:9d1854f06a59b33df403ac',
    measurementId: 'G-845K8279C0',
  },
};

// @ts-ignore
export const fbApp = global.fbApp || initializeApp(fbConfig[config.env]);

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  global.fbApp = fbApp;
}

export const fbAuth = getAuth(fbApp);
export const fbStore = getFirestore(fbApp);

export function getFBAuthToken(): Promise<string> {
  const user = getAuth().currentUser;
  if (!user) {
    return Promise.reject('get_auth_header:auth_user_not_found');
  }
  return user.getIdToken();
}
