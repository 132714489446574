import { Button, MaterialIcon } from '@koolumbus/web/ui';
import { useRouter } from 'next/router';
import React from 'react';
import { useIntl } from '../../../state/intl';

interface BackButtonProps {}

const BackButton: React.FC<React.PropsWithChildren<BackButtonProps>> = () => {
  const router = useRouter();
  const { lang } = useIntl();

  if (!router.pathname.slice(1).includes('/')) {
    return <div />;
  }

  return (
    <Button
      variant="ghost"
      className="text-gray-600 text-base font-medium inline-flex items-center pr-2 sm:pr-4"
      onClick={router.back}
    >
      <MaterialIcon
        icon="keyboard-backspace"
        className="text-2xl md:text-xl leading-5 text-gray-600 md:mr-3"
      />
      <span className="hidden md:block">
        {
          {
            en: 'Back',
            it: 'Indietro',
            es: 'Back',
          }[lang]
        }
      </span>
    </Button>
  );
};

export default BackButton;
