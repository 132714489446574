import { config } from '@koolumbus/web/config';
import { TRPCLink, httpBatchLink, loggerLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import superjson from 'superjson';
import type { AppRouter } from '../server/app-router';
import { fbAuth } from '../services/firebase.service';
import { getApiTrpcUrl } from './utils/api-utils';

export type ProcedureInput = inferRouterInputs<AppRouter>;
export type ProcedureOutput = inferRouterOutputs<AppRouter>;

export type QueryOutput = ProcedureOutput;

const links: TRPCLink<AppRouter>[] = [];

if (config.env === 'development') {
  links.push(loggerLink());
}

links.push(
  httpBatchLink({
    url: getApiTrpcUrl(config.env),
    maxURLLength: 2083,
    headers: async () => {
      const token = await fbAuth.currentUser?.getIdToken();
      if (token) {
        return {
          Authorization: `Bearer ${token}`,
        };
      }

      return {};
    },
  }),
);

export const trpc = createTRPCNext<AppRouter>({
  config: () => {
    return {
      transformer: superjson,
      links,
    };
  },
});
