import {
  Checkbox,
  Input,
  InputBase,
  InputWrapper,
  Modal,
  NumberInput,
  Select,
  TextInput,
  Textarea,
  createTheme,
} from '@mantine/core';
import { DatePickerInput, DateTimePicker, TimeInput } from '@mantine/dates';
import tailwindTheme from 'tailwindcss/defaultTheme';

const mantheme = createTheme({
  fontFamily: `Inter var, ${tailwindTheme.fontFamily.sans.join(', ')}`,

  primaryColor: 'primary',
  defaultRadius: 'md',

  colors: {
    primary: [
      '#f9ecff',
      '#ebd7f9',
      '#d2acee',
      '#b97fe4',
      '#a359db',
      '#9740d6',
      '#9034d4',
      '#7c26bc',
      '#6e20a9',
      '#601895',
    ],
  },

  breakpoints: {
    xs: '32rem',
    sm: tailwindTheme.screens.sm,
    md: tailwindTheme.screens.md,
    lg: tailwindTheme.screens.lg,
    xl: tailwindTheme.screens.xl,
    '2xl': tailwindTheme.screens['2xl'],
  },

  radius: {
    sm: tailwindTheme.borderRadius.sm,
    md: tailwindTheme.borderRadius.md,
    lg: tailwindTheme.borderRadius.lg,
    xl: tailwindTheme.borderRadius.xl,
    '2xl': tailwindTheme.borderRadius['2xl'],
  },

  components: {
    Input: Input.extend({
      classNames(theme, props, ctx) {
        return {
          wrapper: '[&>label]:mb-2',
          input: 'placeholder-gray-400',
        };
      },
    }),
    Select: Select.extend({
      classNames(theme, props, ctx) {
        return {
          input: 'min-h-[48px] text-base',
          label: 'mb-2 text-base font-normal',
        };
      },
    }),
    Textarea: Textarea.extend({
      classNames(theme, props, ctx) {
        return {
          input: 'text-base',
          label: 'mb-2 text-base font-normal',
        };
      },
    }),
    NumberInput: NumberInput.extend({
      classNames(theme, props, ctx) {
        return {
          input: 'min-h-[48px] text-base',
          label: 'mb-2 text-base font-normal',
        };
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      classNames(theme, props, ctx) {
        return {
          input: 'min-h-[48px] text-base',
          label: 'mb-2 text-base font-normal',
        };
      },
    }),
    DateTimePicker: DateTimePicker.extend({
      classNames: {
        input: 'min-h-[48px] text-base',
        label: 'mb-2 text-base font-normal',
      },
    }),
    TimeInput: TimeInput.extend({
      classNames(theme, props, ctx) {
        return {
          input: 'min-h-[48px] text-base',
          label: 'mb-2 text-base font-normal',
        };
      },
    }),
    InputWrapper: InputWrapper.extend({
      classNames() {
        return {
          label: 'mb-2 text-base font-normal ml-2',
        };
      },
    }),
    TextInput: TextInput.extend({
      classNames(theme, props, ctx) {
        return {
          input: 'min-h-[48px] text-base',
          label: 'mb-2 text-base font-normal',
        };
      },
    }),
    InputBase: InputBase.extend({
      classNames(theme, props, ctx) {
        return {
          input: 'min-h-[48px] text-base',
        };
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        centered: true,
      },
    }),
    Checkbox: Checkbox.extend({
      classNames: {
        body: 'flex flex-row items-center',
        label: 'text-base ',
      },
    }),
  },
});

export default mantheme;
