import { useToast } from '@koolumbus/web/ui';
import { useCallback } from 'react';
import services from '../../services/services';

export default function useErrors() {
  const toast = useToast();

  const handleErr = useCallback(
    (err: any, msg?: string) => {
      // error reporting must be the first operation performed to avoid stacktrace pollution
      services.errors.report(err);
      console.error(err);
      toast.error(msg);
    },
    [toast],
  );

  return handleErr;
}
