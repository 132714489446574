import { useState, useEffect } from 'react';

interface ScrollPosition {
  x: number;
  y: number;
}

export default function useScroll(): ScrollPosition {
  const [position, setPosition] = useState<ScrollPosition>({ x: 0, y: 0 });

  const handleScroll = () => {
    const x = window.scrollX;
    const y = window.scrollY;

    setPosition({ x, y });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return position;
}
