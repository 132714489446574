import React from 'react';
import { DesktopMenu, MobileMenu } from './Menu';
import Header from './header/Header';

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex min-h-full">
      <MobileMenu />

      <div className="hidden lg:flex fixed top-0 bottom-0 left-0 lg:flex-shrink-0 min-h-full z-20">
        <DesktopMenu />
      </div>

      <div className="flex flex-col w-0 flex-1">
        <div className="fixed top-0 left-0 lg:left-64 w-screen lg:w-[calc(100vw-16rem)] z-30">
          <Header />
        </div>

        <main className="flex-1 relative focus:outline-none pt-16 pb-10 lg:pl-64 overflow-y-hidden">
          <div className="p-0 sm:p-4 md:p-6 lg:p-8 xl:p-10">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
