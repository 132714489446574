interface LogoIconProps {
  white?: boolean;
  size?: number;
}

const KoolumbusLogo: React.FC<React.PropsWithChildren<LogoIconProps>> = ({
  white = false,
  size = 20,
}) => {
  return (
    <div style={{ width: size }}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src="/images/logo.png" alt="logo" />
    </div>
  );
};

export default KoolumbusLogo;
