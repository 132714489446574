import { Locale as ConfigLangKey } from '@koolumbus/shared/config';

export type LangKey = ConfigLangKey;

export type TxCommon = {
  archive: string;
  calendar: string;
  rooms: string;
  auctions: string;
  map: string;
  details: string;
  rowsPerPage: string;
  notes: string;
  property: string;
  guests: string;
  payments: string;
  bookings: string;
  contracts: string;
  documents: string;
  unknown: string;
  alloggi: string;
  zones: string;
  images: string;
  workplaces: string;
  name: string;
  actions: {
    add: string;
    edit: string;
    delete: string;
    remove: string;
    search: string;
    submit: string;
    archive: string;
    close: string;
    hide: string;
    cancel: string;
    save: string;
    confirm: string;
    restore: string;
  };
  doYouWantToProceed: string;
  models: {
    common: {
      creationDate: string;
      archivedDate: string;
    };
    propertyCategories: {
      short: string;
      medium: string;
    };
    bookingStatus: {
      pending: string;
      confirmed: string;
      cancelled: string;
      rejected: string;
      blocked: string;
    };
    booking: {
      customerType: string;
      checkinTime: string;
      checkoutTime: string;
      source: string;
      number: string;
      status: string;
    };
    bookingPayment: {
      stay: string;
      cityTax: string;
      cleaning: string;
      deposit: string;
      depositReturn: string;
      utility: string;
    };
    guest: {
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
      docNumber: string;
      docPlace: string;
      docType: string;
      address: string;
      fiscalCode: string;
      gender: string;
      country: string;
      region: string;
      birthPlace: string;
      languages: string;
    };
  };
};

const EN: TxCommon = {
  archive: 'Archive',
  calendar: 'Calendar',
  map: 'Map',
  rooms: 'Rooms',
  auctions: 'Auctions',
  details: 'Details',
  contracts: 'Contracts',
  documents: 'Documents',
  notes: 'Notes',
  property: 'Property',
  bookings: 'Bookings',
  payments: 'Payments',
  unknown: 'Unknown',
  guests: 'Guests',
  zones: 'Zones',
  rowsPerPage: 'Rows per page',
  alloggi: 'Rooms',
  workplaces: 'Workplaces',
  images: 'Images',
  name: 'Name',
  actions: {
    add: 'New',
    edit: 'Edit',
    delete: 'Delete',
    remove: 'Remove',
    search: 'Search',
    submit: 'Submit',
    archive: 'Archive',
    close: 'Close',
    hide: 'Hide',
    save: 'Save',
    cancel: 'Cancel',
    restore: 'Restore',
    confirm: 'Confirm',
  },
  doYouWantToProceed: 'Do you want to proceed?',
  models: {
    common: {
      creationDate: 'Creation date',
      archivedDate: 'Archiving date',
    },
    propertyCategories: {
      short: 'Short',
      medium: 'Medium',
    },
    bookingStatus: {
      pending: 'Pending',
      confirmed: 'Confirmed',
      cancelled: 'Cancelled',
      rejected: 'Rejected',
      blocked: 'Blocked',
    },
    booking: {
      customerType: 'Customer type',
      checkinTime: 'Check in time',
      checkoutTime: 'Check out time',
      status: 'Status',
      source: 'Source',
      number: 'Booking number',
    },
    bookingPayment: {
      stay: 'Stay',
      cityTax: 'City tax',
      cleaning: 'Cleaning',
      deposit: 'Deposit',
      depositReturn: 'Deposit return',
      utility: 'Utility',
    },
    guest: {
      firstName: 'First name',
      lastName: 'Last name',
      phone: 'Phone',
      email: 'Email address',
      docNumber: 'Document number',
      docPlace: 'Document issue location',
      docType: 'Document type',
      address: 'Address',
      gender: 'Gender',
      fiscalCode: 'Fiscal code',
      country: 'Country',
      region: 'Region',
      birthPlace: 'Birth place',
      languages: 'Languages',
    },
  },
};

const IT: TxCommon = {
  archive: 'Archivio',
  rooms: 'Alloggi',
  auctions: 'Aste',
  property: 'Proprietà',
  calendar: 'Calendario',
  map: 'Mappa',
  details: 'Dettagli',
  contracts: 'Contratti',
  documents: 'Documenti',
  notes: 'Note',
  guests: 'Ospiti',
  payments: 'Pagamenti',
  bookings: 'Prenotazioni',
  rowsPerPage: 'Righe per pagina',
  unknown: 'Sconosciuto',
  alloggi: 'Alloggi',
  workplaces: 'Sedi',
  images: 'Immagini',
  zones: 'Zone',
  name: 'Nome',
  actions: {
    add: 'Aggiungi',
    edit: 'Modifica',
    delete: 'Elimina',
    remove: 'Rimuovi',
    search: 'Cerca',
    submit: 'Submit',
    archive: 'Archivia',
    save: 'Salva',
    close: 'Chiudi',
    hide: 'Nascondi',
    cancel: 'Annulla',
    confirm: 'Conferma',
    restore: 'Ripristina',
  },
  doYouWantToProceed: 'Vuoi procedere?',
  models: {
    common: {
      creationDate: 'Data di creazione',
      archivedDate: 'Data di archiviazione',
    },
    propertyCategories: {
      short: 'Brevi',
      medium: 'Medi',
    },
    bookingStatus: {
      pending: 'Pending',
      confirmed: 'Confermata',
      cancelled: 'Cancellata',
      rejected: 'Respinta',
      blocked: 'Blocco',
    },
    booking: {
      customerType: 'Tipologia di cliente',
      checkinTime: 'Orario del check in',
      checkoutTime: 'Orario del check out',
      status: 'Stato',
      source: 'Portale',
      number: 'Codice prenotazione',
    },
    bookingPayment: {
      stay: 'Permanenza',
      cityTax: 'Tassa di soggiorno',
      cleaning: 'Pulizie',
      deposit: 'Deposito',
      depositReturn: 'Restituzione deposito',
      utility: 'Utenze',
    },
    guest: {
      firstName: 'Nome',
      lastName: 'Cognome',
      phone: 'Telefono',
      email: 'Indirizzo email',
      docNumber: 'Numero del documento',
      docPlace: 'Luogo di rilascio del documento',
      docType: 'Tipo di documento',
      address: 'Indirizzo',
      fiscalCode: 'Codice fiscale',
      gender: 'Sesso',
      country: 'Paese',
      region: 'Regione',
      birthPlace: 'Luogo di nascita',
      languages: 'Lingue',
    },
  },
};

type TxsCommon = {
  [key in LangKey]: TxCommon;
};

const commonTxs: TxsCommon = {
  it: IT,
  en: EN,
};

export function getCommonTxs(lang: LangKey): TxCommon {
  return commonTxs[lang];
}
