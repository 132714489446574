import { allRolesExcept, hasRoles, internalRoles } from '@koolumbus/shared/utils';
import { User, UserRole } from '@prisma/client';
import { createContext, useContext, useMemo } from 'react';
import { useAuthState } from '../../state/auth';

const RolesSwitchContext = createContext<{ roles: UserRole[] }>({ roles: [] });

export function useRolesSwitch() {
  return useContext(RolesSwitchContext);
}

interface RolesSwitchProps {
  children: React.ReactNode;
  user?: User;
  admin?: boolean;
  internal?: true;
  external?: true;
  roles?: UserRole[];
}

const RolesSwitch: React.FC<React.PropsWithChildren<RolesSwitchProps>> = ({
  children,
  user,
  admin,
  internal,
  external,
  roles: _roles,
}) => {
  const { user: authUser } = useAuthState();

  const authorizedRoles = useMemo(() => {
    const roles: UserRole[] = [];

    if (admin) {
      roles.push('ADMIN');
    }

    if (internal) {
      roles.push(...internalRoles);
    }

    if (external) {
      roles.push(...allRolesExcept(...internalRoles));
    }

    if (typeof _roles !== 'undefined' && _roles.length > 0) {
      roles.push(..._roles);
    }

    return roles;
  }, [_roles, admin, external, internal]);

  const authorized = useMemo(
    () => hasRoles(user ?? authUser, ...authorizedRoles),
    [authUser, authorizedRoles, user],
  );

  if (!authorized) {
    return null;
  }

  return (
    <RolesSwitchContext.Provider value={{ roles: authorizedRoles }}>
      {children}
    </RolesSwitchContext.Provider>
  );
};

export default RolesSwitch;
