import { Menu, Transition } from '@headlessui/react';
import { Avatar, MaterialIcon, MaterialIconName, useToast } from '@koolumbus/web/ui';
import { createTxs } from '@koolumbus/web/utils';
import clsx from 'clsx';
import { signOut } from 'firebase/auth';
import Link from 'next/link';
import React, { Fragment } from 'react';
import { trpc } from '../../../lib/trpc';
import { fbAuth } from '../../../services/firebase.service';
import { useAuthState } from '../../../state/auth';
import { useDialogs } from '../../../state/dialogs';
import { delay } from '../../../utils/delay';
import useErrors from '../../../utils/hooks/errors.hooks';

interface UserMenuProps {}

const UserMenu: React.FC<React.PropsWithChildren<UserMenuProps>> = () => {
  const handleErr = useErrors();
  const tx = useTxs();
  const { user } = useAuthState();
  const { confirm } = useDialogs();
  const toast = useToast();
  const { client: api } = trpc.useUtils();

  async function performLogout() {
    await delay(1000);
    return signOut(fbAuth);
  }

  function onLogout() {
    confirm({
      title: tx.logoutDialog.title,
      content: tx.logoutDialog.content,
      onMainAction: performLogout,
      variant: 'warning',
    });
  }

  async function handleResetPsw() {
    try {
      await api.auth.resetPassword.mutate({ email: user!.email });
      toast.success();
    } catch (err) {
      handleErr(err);
    }
  }

  function onResetPsw() {
    confirm({
      title: 'Reset password',
      content: tx.resetPasswordDialog.content(user?.email ?? '-'),
      onMainAction: handleResetPsw,
    });
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={clsx(
            'inline-flex flex-center rounded-full mr-2 md:mr-0',
            'bg-white hover:bg-gray-100',
            'border-gray-200',
            'md:p-[3px] md:pl-3 md:border',
          )}
        >
          <span className="hidden md:flex justify-center items-center mr-3">
            <MaterialIcon icon="menu" className={clsx('text-xl leading-5', 'text-gray-500')} />
          </span>
          <Avatar src={user?.imageUrl} size="sm" className="shadow-sm" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <Link href={`/users/${user!.id}`}>
            <div className="px-4 py-3 cursor-pointer hover:bg-gray-100">
              <p className="text-sm text-gray-400">Signed in as</p>
              <p className="text-md text-gray-600 truncate">{user?.email ?? 'Unknown email'}</p>
            </div>
          </Link>

          <div className="py-1">
            <UserMenuItem label="Reset password" onClick={onResetPsw} icon="vpn-key" />
            <UserMenuItem label="Logout" onClick={onLogout} icon="lock-open" />
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

interface UserMenuItemProps {
  icon: MaterialIconName;
  onClick: () => void;
  label: string;
}

const UserMenuItem: React.FC<React.PropsWithChildren<UserMenuItemProps>> = ({
  icon,
  onClick,
  label,
}) => {
  return (
    <Menu.Item as="button" className="w-full text-left" onClick={onClick}>
      {({ active }) => (
        <p
          className={clsx(
            'group flex items-center px-4 py-2 cursor-pointer',
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
          )}
        >
          <MaterialIcon
            className="mr-4 text-lg text-gray-400"
            variant="outlined"
            icon={icon}
            aria-hidden="true"
          />
          {label}
        </p>
      )}
    </Menu.Item>
  );
};

const useTxs = createTxs({
  en: {
    logout: 'Logout',
    logoutDialog: {
      title: 'Logout',
      content: 'Do you really want to logout?',
    },
    resetPasswordDialog: {
      content: (email: string) =>
        `An email with a reset password link will be sent to ${email}. Do you want to proceed?`,
    },
  },
  it: {
    logout: 'Esci',
    logoutDialog: {
      title: 'Esci',
      content: 'Vuoi davvero uscire?',
    },
    resetPasswordDialog: {
      content: (email) =>
        `Ti verrà inviata una mail con un link per il reset della password all\`indirizzo ${email}. Vuoi procedere?`,
    },
  },
});

export default UserMenu;
