import create from 'zustand';
import { TenantUser } from '../lib/models';

type AuthState = {
  user: TenantUser | undefined;
  auth: boolean;
  setUser: (user: TenantUser) => void;
  resetAuth: () => void;
};

const useAuthStore = create<AuthState>((set) => ({
  auth: false,
  user: undefined,
  setUser: (user) => set({ auth: true, user }),
  resetAuth: () => set({ auth: false, user: undefined }),
}));

export const useAuthState = useAuthStore;

export const useAuthUser = (): AuthState['user'] => {
  return useAuthState().user;
};

export function useCurrentUser(): TenantUser {
  const user = useAuthUser();
  if (!user) {
    throw new Error('Current user not found');
  }

  return user;
}
