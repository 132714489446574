import { Spinner } from '@koolumbus/web/ui';
import React from 'react';

interface SplashScreenProps {}

const SplashScreen: React.FC<React.PropsWithChildren<SplashScreenProps>> = () => {
  return (
    <div className="flex-center h-screen w-screen">
      <div className="flex-center flex-col">
        <h3 className="text-xl mb-11">Koolumbus PMS</h3>
        <Spinner size="xl" className="border-[3px]" />
      </div>
    </div>
  );
};

export default SplashScreen;
