import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { config } from '@koolumbus/web/config';
import { MaterialIcon, MaterialIconName } from '@koolumbus/web/ui';
import { getTenantLogoUrl } from '@koolumbus/web/utils';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment, useMemo } from 'react';
import { useRoutes } from '../../config/routes';
import { useAuthState } from '../../state/auth';
import { useDrawer } from '../../state/drawer';
import KoolumbusLogo from '../common/KoolumbusLogo';

interface MenuItemProps {
  path: string;
  label: string;
  icon: MaterialIconName;
}

const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({ path, label, icon }) => {
  const router = useRouter();
  const { isOpen: isDrawerOpen, onClose: onCloseDrawer } = useDrawer();

  const onClick = () => {
    if (isDrawerOpen) {
      onCloseDrawer();
    }
  };

  const selected = useMemo(() => {
    const parts = router.pathname.split('/');

    if (parts[1] === '') {
      return router.pathname === path;
    }

    return path.includes(parts[1]);
  }, [path, router.pathname]);

  return (
    <Link
      href={{ pathname: path, query: path === router.pathname ? router.query : undefined }}
      onClick={onClick}
      className={clsx(
        'group flex items-center px-4 py-4 font-medium rounded-md cursor-pointer',
        selected ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
      )}
    >
      <MaterialIcon
        icon={icon}
        variant="outlined"
        className={clsx(
          'mr-4 flex-shrink-0 h-6 w-7',
          selected ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
        )}
        aria-hidden="true"
      />
      {label}
    </Link>
  );
};

export const DesktopMenu = () => {
  const routes = useRoutes();

  return (
    <div className="flex flex-col w-64 min-h-full">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex-1 flex flex-col min-h-0">
        <MenuTopSection />

        <div className="flex-1 flex flex-col overflow-y-auto bg-gray-800">
          <nav className="flex-1 px-2 py-4 space-y-1.5 mt-3">
            {routes.map((item) => (
              <MenuItem
                key={item.path}
                path={item.path}
                label={item.menuLabel}
                icon={item.menuIcon}
              />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export const MobileMenu = () => {
  const routes = useRoutes();
  const { isOpen: isDrawerOpen, onClose: onCloseDrawer } = useDrawer();

  return (
    <Transition.Root show={isDrawerOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={onCloseDrawer}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-gray-800">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={onCloseDrawer}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>

            <MenuTopSection />

            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                {routes.map((item) => (
                  <MenuItem
                    key={item.path}
                    path={item.path}
                    label={item.menuLabel}
                    icon={item.menuIcon}
                  />
                ))}
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const MenuTopSection = () => {
  const { user } = useAuthState();

  return (
    <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
      {user?.tenant.hasLogo ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src={getTenantLogoUrl(config.env, user.tenantId)}
          alt="company-logo"
          className="w-[34px] h-[34px] rounded-full"
        />
      ) : (
        // TODO: white logo
        <KoolumbusLogo size={24} />
      )}
      <p className="text-white ml-6 mb-[1px] text-[22px]">
        {user?.tenant.plan !== 'BUSINESS' ? 'Koolumbus' : user.tenant.name}
      </p>
    </div>
  );
};
