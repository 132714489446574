import { Transition } from '@headlessui/react';
import { config } from '@koolumbus/web/config';
import { IconButton, MaterialIcon, Spinner } from '@koolumbus/web/ui';
import React, { Fragment } from 'react';
import { useDrawer } from '../../../state/drawer';
import { usePageTitle } from '../../../state/title';
import useScroll from '../../../utils/hooks/scroll.hooks';
import useRouterLoading from '../../../utils/hooks/useRouterLoading';
import RolesSwitch from '../../common/RolesSwitch';
import BackButton from './BackButton';
import InboxButton from './InboxButton';
import LanguagePicker from './LanguagePicker';
import NotificationsButton from './NotificationsButton';
import UserMenu from './UserMenu';

const Header = () => {
  const scroll = useScroll();
  const { onOpen: onOpenDrawer } = useDrawer();
  const isRouterLoading = useRouterLoading();

  const title = usePageTitle();

  return (
    <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow px-1 md:px-4 xl:px-6">
      <div className="flex items-center">
        <button
          type="button"
          className="inline-flex lg:hidden justify-center items-center px-4 text-gray-500 focus:outline-none"
          onClick={onOpenDrawer}
        >
          <span className="sr-only">Open sidebar</span>
          <MaterialIcon icon="menu" className="h-6 w-6" aria-hidden="true" />
        </button>

        {isRouterLoading ? (
          <div className="flex items-center ml-3.5">
            <Spinner size="md" className="border-primary border-[3px]" />
          </div>
        ) : (
          <BackButton />
        )}

        <Transition
          as={Fragment}
          show={scroll.y >= 110}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="hidden sm:flex h-full items-center px-[5px]">
            <p className="text-base ml-[25px]">{title}</p>
          </div>
        </Transition>
      </div>

      <div className="flex-1 px-4 flex justify-end">
        <div className="ml-4 flex items-center md:ml-6">
          <div className="flex space-x-2 mr-7">
            <div className="hidden md:block">
              {config.env !== 'production' && (
                <IconButton
                  variant="ghost"
                  className="hover:!bg-red-50"
                  aria-label="simulate error"
                  icon={
                    <MaterialIcon
                      className="text-red-600"
                      icon="error-outline"
                      style={{ fontSize: 22 }}
                    />
                  }
                  onClick={() => {
                    throw new Error('fake-error');
                  }}
                />
              )}
            </div>

            <RolesSwitch admin>
              <InboxButton />
            </RolesSwitch>

            <NotificationsButton />

            <LanguagePicker />
          </div>

          <UserMenu />
        </div>
      </div>
    </div>
  );
};

export default Header;
