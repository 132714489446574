import { hasRoles, isAdmin } from '@koolumbus/shared/utils';
import { MaterialIconName } from '@koolumbus/web/ui';
import { createTxs } from '@koolumbus/web/utils';
import { useAuthState } from '../state/auth';
import { useIntl } from '../state/intl';

export interface PMSRouteProps {
  path: string;
  menuLabel: string;
  menuIcon: MaterialIconName;
}

export type PMSRoutes = PMSRouteProps[];

export function useRoutes(): PMSRoutes {
  const user = useAuthState((store) => store.user);
  const { txc } = useIntl();
  const tx = useTxs();

  const routes: PMSRoutes = [];

  routes.push({
    path: '/',
    menuLabel: 'Dashboard',
    menuIcon: 'inbox',
  });

  routes.push({
    path: '/properties',
    menuLabel: tx.properties,
    menuIcon: 'home',
  });

  // Bookings
  routes.push({
    path: '/bookings',
    menuLabel: tx.bookings,
    menuIcon: 'book',
  });

  routes.push({
    path: '/tasks',
    menuLabel: tx.tasks,
    menuIcon: 'playlist-add-check-circle',
  });

  // Auctions
  if (isAdmin(user)) {
    routes.push({
      path: '/auctions',
      menuLabel: txc.auctions,
      menuIcon: 'card-travel',
    });
  }

  // Guests
  if (hasRoles(user, 'ADMIN', 'MANAGER', 'RECEIVER')) {
    routes.push({
      path: '/guests',
      menuLabel: tx.guests,
      menuIcon: 'assignment-ind',
    });
  }

  // Users
  if (isAdmin(user)) {
    routes.push({
      path: '/users',
      menuLabel: tx.users,
      menuIcon: 'people',
    });
  }

  // CRM
  if (isAdmin(user)) {
    routes.push({
      path: '/customers',
      menuLabel: 'CRM',
      menuIcon: 'supervised-user-circle',
    });
  }

  // Inbox
  if (isAdmin(user)) {
    routes.push({
      path: '/inbox',
      menuLabel: 'Inbox',
      menuIcon: 'message',
    });
  }

  return routes;
}

const useTxs = createTxs({
  en: {
    properties: 'Properties',
    bookings: 'Bookings',
    tasks: 'Tasks',
    guests: 'Guests',
    users: 'Users',
  },
  it: {
    properties: 'Proprietà',
    bookings: 'Prenotazioni',
    tasks: 'Interventi',
    guests: 'Ospiti',
    users: 'Utenti',
  },
});
