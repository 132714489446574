import { isAdmin } from '@koolumbus/shared/utils';
import { config } from '@koolumbus/web/config';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { User as FireUser, onAuthStateChanged, signOut } from 'firebase/auth';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import 'react-image-lightbox/style.css';
import 'tippy.js/dist/tippy.css';
import SplashScreen from '../components/common/SplashScreen';
import LandingPage from '../components/landing/LandingPage';
import MainLayout from '../components/layout/MainLayout';
import mantheme from '../lib/mantheme';
import { trpc } from '../lib/trpc';
import { fbAuth } from '../services/firebase.service';
import services from '../services/services';
import { useAuthState } from '../state/auth';
import { DialogsProvider } from '../state/dialogs';
import { TitleProvider } from '../state/title';
import '../style/react-day-picker.css';
import '../style/room-type-calendar.css';
import '../style/styles.css';
import useErrors from '../utils/hooks/errors.hooks';

function CustomApp({ Component, pageProps }: AppProps) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    console.log(
      `KOOLUMBUS PMS STARTED - NODE_ENV: ${process.env.NODE_ENV} - KOOLUMBUS_ENV: ${config.env}`,
    );

    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <Head>
        <title>Koolumbus PMS</title>
      </Head>

      <MantineProvider withCssVariables theme={mantheme}>
        <ModalsProvider>
          <DialogsProvider>
            <TitleProvider>
              <RootContent>
                <Component {...pageProps} />
              </RootContent>
            </TitleProvider>
          </DialogsProvider>
        </ModalsProvider>
      </MantineProvider>

      <Toaster />
    </>
  );
}

const RootContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter();

  if (new RegExp('^/(signup|reset-password)/?$').test(router.pathname)) {
    return <>{children}</>;
  }

  return <AuthSwitch>{children}</AuthSwitch>;
};

const AuthSwitch: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter();
  const handleErr = useErrors();

  const [loading, setLoading] = useState(true);
  const { resetAuth, setUser, auth } = useAuthState();

  const { mutateAsync: login } = trpc.auth.login.useMutation();

  useEffect(() => {
    const handleLogin = async (fbUser: FireUser) => {
      try {
        const { token } = await fbUser.getIdTokenResult();
        const user = await login({ token });

        if (!isAdmin(user) && window.location.pathname === '/') {
          await router.replace('/properties');
        }

        setUser(user);
        setLoading(false);
      } catch (err) {
        handleErr(err);
        signOut(fbAuth);
        resetAuth();
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(fbAuth, async (user) => {
      if (user) {
        await handleLogin(user);
        services.errors.setUser(user.uid, user.email);
      } else {
        resetAuth();
        setLoading(false);
        services.errors.resetUser();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [handleErr, login, resetAuth, router, setUser]);

  if (loading) return <SplashScreen />;
  if (auth) return <MainLayout>{children}</MainLayout>;
  return <LandingPage />;
};

export default trpc.withTRPC(CustomApp);
