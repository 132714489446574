interface CopyrightProps {}

const Copyright: React.FC<React.PropsWithChildren<CopyrightProps>> = () => {
  return (
    <div className="mt-6">
      <a color="inherit" href="https://myothis.com/" target={'_blank'} rel="noopener noreferrer">
        <p className="text-center text-gray-500">
          {'Copyright © '}
          Myothis {new Date().getFullYear()}
        </p>
      </a>
    </div>
  );
};

export default Copyright;
