export const COLORS = {
  BG: '#F9F9FC',
  MAIN: '#232F3E',
  SECONDARY: '#FBC02D',
  SHADOW: 'rgba(53, 64, 82, 0.05)',
  SHADOW_ELEVATED: 'rgba(53, 64, 82, 0.3)',
  DRAWER_HEADER: '#232F3E',
  DRAWER_BG: '#1B2430',
  SUBTITLE: 'rgba(0, 0, 0, 0.4)',
  DIVIDER: 'rgba(27, 36, 48, 0.08)',
};

export const DRAWER_WIDTH = 260;
export const DRAWER_MINI_WIDTH = 77;

export const DEFAULT_ROWS_PER_PAGE = 25;

export const TEST_DATA = {
  email: 'pms.user@dev.myothis.com',
  password: 'knByfcoczRB8aFag4iUFPhK4',
};
