import { useToast } from '@koolumbus/web/ui';
import { createTxs } from '@koolumbus/web/utils';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { TEST_DATA } from '../../config/constants';
import { trpc } from '../../lib/trpc';
import { fbAuth } from '../../services/firebase.service';
import useErrors from '../../utils/hooks/errors.hooks';
import Copyright from './Copyright';
import LoginForm from './LoginForm';

const LandingPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const handleErr = useErrors();
  const toast = useToast();
  const tx = useTxs();
  const { client: api } = trpc.useUtils();

  const [loading, setLoading] = useState(false);

  async function handleSubmit(email: string, password: string) {
    setLoading(true);

    if (email === '' && password === '') {
      email = TEST_DATA.email;
      password = TEST_DATA.password;
    }

    try {
      await signInWithEmailAndPassword(fbAuth, email, password);
    } catch (err) {
      setLoading(false);
      toast.error(tx.error);
    }
  }

  async function handleResetPsw(email: string) {
    setLoading(true);

    try {
      await api.auth.resetPassword.mutate({ email });
      toast.success();
    } catch (err) {
      handleErr(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex-center flex-col w-full h-screen px-6">
      <div className="flex-center flex-col w-full max-w-[400px]">
        <h2 className="mb-3 font-semibold text-2xl">{tx.title}</h2>

        <h3 className="font-medium">{'Koolumbus PMS'}</h3>

        <div className="pt-5 w-full">
          <LoginForm
            handleSubmit={handleSubmit}
            handleResetPsw={handleResetPsw}
            loading={loading}
          />
        </div>
      </div>

      <Copyright />
    </div>
  );
};

const useTxs = createTxs({
  en: {
    title: 'Sign in to your account',
    error: 'Unable to log in with this credentials',
    notHavePsw: 'I do not have a password yet',
  },
  it: {
    title: 'Accedi al tuo account',
    error: "Impossibile effettuare l'accesso con queste credenziali",
    notHavePsw: 'Non ho ancora una password',
  },
});

export default LandingPage;
