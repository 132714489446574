import ErrorsService from './errors.service';
import MapsService from './maps.service';

class Services {
  public errors: ErrorsService;
  public maps: MapsService;

  constructor() {
    this.errors = new ErrorsService();
    this.maps = new MapsService();
  }
}

const services = new Services();
export default services;
