import { HeaderButton, NotificationGroup, NotificationsDrawer } from '@koolumbus/web/ui';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { trpc } from '../../../lib/trpc';

interface NotificationsButtonProps {}

const NotificationsButton: React.FC<React.PropsWithChildren<NotificationsButtonProps>> = () => {
  const router = useRouter();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const trpcCtx = trpc.useUtils();

  const { data } = trpc.notifications.list.useQuery({});
  const items = data?.items ?? [];

  const { mutateAsync: performRead, isLoading } = trpc.notifications.read.useMutation({
    onSuccess: () => {
      trpcCtx.notifications.list.invalidate();
    },
  });

  const onItemDismiss = async (group: NotificationGroup) => {
    if (isLoading) return;

    try {
      await performRead({ ids: group.ids });
    } catch (err) {
      console.error(err);
    }
  };

  const onItemClick = async (group: NotificationGroup) => {
    if (isLoading) return;

    setDrawerOpen(false);

    await onItemDismiss(group);

    switch (group.event) {
      case 'NEW_BOOKING':
        if (group.count > 1) {
          return router.push('/bookings');
        } else {
          return router.push(`/bookings/${group.resourceId}`);
        }

      case 'AUCTION_BID_ACCEPTED':
      case 'AUCTION_BID_REJECTED':
        return router.push(`/auctions/${group.resourceId}`);
    }
  };

  return (
    <>
      <HeaderButton
        isFloating={false}
        icon="notifications"
        tooltip="Notifications"
        onClick={() => setDrawerOpen(true)}
        hasRedDot={items.length > 0}
      />

      <NotificationsDrawer
        items={items}
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        onGroupClick={onItemClick}
        onGroupDismiss={onItemDismiss}
      />
    </>
  );
};

export default NotificationsButton;
