import services from './services';

export default class MapsService {
  public getPlacesPredictions(
    searchTerm: string,
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    const autocomplete = new google.maps.places.AutocompleteService();
    return new Promise<google.maps.places.AutocompletePrediction[]>((resolve, reject) => {
      autocomplete.getPlacePredictions(
        {
          types: ['address'],
          input: searchTerm,
        },
        (res, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (res?.length) {
              resolve(res);
            } else {
              resolve([]);
            }
          } else if (
            status === google.maps.places.PlacesServiceStatus.NOT_FOUND ||
            status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
          ) {
            resolve([]);
          } else {
            reject();
            services.errors.report({ type: 'MAPS API ERROR', status, searchTerm });
          }
        },
      );
    });
  }

  public getPlacePosition(placeId: string): Promise<number[] | null> {
    const geocoder = new google.maps.Geocoder();
    return new Promise<number[] | null>((resolve, reject) => {
      geocoder.geocode({ placeId }, (res, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (res?.length) {
            const place = res[0];
            resolve([place.geometry.location.lat(), place.geometry.location.lng()]);
          }
          resolve(null);
        } else {
          reject();
        }
      });
    });
  }
}
