import React, { createContext, useContext, useLayoutEffect, useState } from 'react';

export const TitleContext = createContext<[string, (value: string) => void]>(['', () => {}]);

export const TitleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [title, setTitle] = useState<string>('');
  return <TitleContext.Provider value={[title, setTitle]}>{children}</TitleContext.Provider>;
};

export const useTitle = () => useContext(TitleContext);

export const usePageTitle = (title?: string | null) => {
  const [_title, setTitle] = useTitle();

  useLayoutEffect(() => {
    if (typeof title !== 'undefined') {
      setTitle(title ?? '');
    }
  }, [setTitle, title]);

  return _title;
};
