import { MaterialIcon } from '@koolumbus/web/ui';
import React, { DOMAttributes } from 'react';

interface TogglePasswordVisibilityButtonProps {
  visible: boolean;
  onToggle: () => void;
}

const TogglePasswordVisibilityButton: React.FC<
  React.PropsWithChildren<TogglePasswordVisibilityButtonProps>
> = ({ visible, onToggle: handleToggle }) => {
  const onToggle: DOMAttributes<HTMLButtonElement>['onClick'] = (ev) => {
    ev.preventDefault();
    handleToggle();
  };

  return (
    <div className="absolute max-h-12 inset-y-0 right-0 pr-1.5 flex items-center">
      <button
        type="button"
        className="flex-center bg-gray-100 h-9 w-9 rounded-md"
        onClick={onToggle}
        aria-label="Show or hide password"
      >
        <MaterialIcon
          icon={visible ? 'visibility-off' : 'visibility'}
          className="text-gray-500"
          aria-hidden="true"
          style={{ fontSize: 18 }}
        />
      </button>
    </div>
  );
};

export default TogglePasswordVisibilityButton;
