import { ConfirmDialog, ConfirmDialogProps } from '@koolumbus/web/ui';
import React, {
  Dispatch,
  ReducerAction,
  ReducerState,
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState,
} from 'react';

// State

interface DialogProps {
  title: string;
  content: React.ReactNode | string;
  variant: ConfirmDialogProps['variant'];
  mainActionLabel: string;
  onMainAction: () => void | Promise<any>;
  onCancel: () => void;
}

type DialogsState = {
  open: boolean;
  props: DialogProps;
};

const defaultConfirmDialogProps: DialogProps = {
  title: '',
  content: '',
  variant: 'default',
  mainActionLabel: 'Confirm',
  onMainAction: () => {},
  onCancel: () => {},
};

const initialState: DialogsState = {
  open: false,
  props: defaultConfirmDialogProps,
};

// Actions

const OPEN_CONFIRM_DIALOG_ACTION_TYPE = 'OPEN_CONFIRM_DIALOG';

type OpenConfirmDialogAction = {
  type: 'OPEN_CONFIRM_DIALOG';
  props: Partial<DialogProps>;
};

function getOpenConfirmDialogAction(props: Partial<DialogProps>): OpenConfirmDialogAction {
  return {
    type: OPEN_CONFIRM_DIALOG_ACTION_TYPE,
    props,
  };
}

const CLOSE_DIALOG_ACTION_TYPE = 'CLOSE_DIALOG';

type CloseDialogAction = {
  type: 'CLOSE_DIALOG';
};

const getCloseDialogAction = (): CloseDialogAction => ({
  type: CLOSE_DIALOG_ACTION_TYPE,
});

type DialogsAction = OpenConfirmDialogAction | CloseDialogAction;

// Reducer

type DialogsStateReducer = (state: DialogsState, action: DialogsAction) => DialogsState;

function dialogsReducer(state: DialogsState, action: DialogsAction): DialogsState {
  if (action.type === OPEN_CONFIRM_DIALOG_ACTION_TYPE) {
    return {
      open: true,
      props: {
        ...defaultConfirmDialogProps,
        ...action.props,
      },
    };
  }

  if (action.type === CLOSE_DIALOG_ACTION_TYPE) {
    return {
      open: false,
      props: state.props,
    };
  }

  return state;
}

// Context

type DialogsContextState = [
  ReducerState<DialogsStateReducer>,
  Dispatch<ReducerAction<DialogsStateReducer>>,
];
const DialogsContext = createContext<DialogsContextState>([initialState, () => {}]);

// Provider

export const DialogsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(dialogsReducer, initialState);
  const [loading, setLoading] = useState(false);

  const { open, props } = state;

  const handleClose = () => {
    dispatch(getCloseDialogAction());
  };

  const handleMainAction = async () => {
    const res = props.onMainAction();

    if (res instanceof Promise) {
      setLoading(true);
      await res;
    }

    handleClose();

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleCancelConfirm = () => {
    handleClose();
    props.onCancel();
  };

  return (
    <DialogsContext.Provider value={[state, dispatch]}>
      {children}

      {/* <SimpleDialog
        title={props.title}
        content={props.content}
        loading={loading}
        open={open}
        mainActionLabel={props.mainActionLabel}
        cancelLabel={props.cancelLabel}
        onMainAction={handleMainAction}
        onCancel={handleCancelConfirm}
      /> */}

      <ConfirmDialog
        title={props.title}
        body={props.content}
        variant={props.variant}
        isOpen={open}
        onClose={handleCancelConfirm}
        onConfirm={handleMainAction}
        confirmLabel={props.mainActionLabel}
      />
    </DialogsContext.Provider>
  );
};

export const useDialogs = () => {
  const [state, dispatch] = useContext<DialogsContextState>(DialogsContext);
  // const {
  //   txc: {
  //     actions: { confirm: confirmLabel },
  //   },
  // } = useIntl();

  const confirm = useCallback(
    (props: Partial<DialogProps>) => {
      if (state.open) {
        dispatch(getCloseDialogAction());
      }

      const finalProps = {
        ...defaultConfirmDialogProps,
        ...props,
      };

      dispatch(getOpenConfirmDialogAction(finalProps));
    },
    [dispatch, state.open],
  );

  return { confirm };
};
