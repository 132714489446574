import { IS_DEV } from '@koolumbus/web/config';
import { Button, MaterialIcon, useToast } from '@koolumbus/web/ui';
import { createTxs } from '@koolumbus/web/utils';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { z as zod } from 'zod';
import { useDialogs } from '../../state/dialogs';
import { formValidate } from '../../state/forms/helpers.forms';
import { useIntl } from '../../state/intl';
import TogglePasswordVisibilityButton from '../common/forms/set-password/TogglePasswordVisibilityButton';

interface LoginFormProps {
  loading: boolean;
  handleSubmit: (email: string, password: string) => void;
  handleResetPsw: (email: string) => Promise<void>;
}

const formSchema = zod.object({
  email: zod.string().email().nonempty(),
  password: zod.string().nonempty(),
});

const LoginForm: React.FC<React.PropsWithChildren<LoginFormProps>> = ({
  handleSubmit,
  loading,
  handleResetPsw,
}) => {
  const { txc } = useIntl();
  const tx = useTxs();
  const toast = useToast();
  const { confirm } = useDialogs();
  const [showPassword, setShowPassword] = useState(false);

  type LoginFormValues = zod.TypeOf<typeof formSchema>;

  const form = useFormik<LoginFormValues>({
    validate: (values) => {
      if (IS_DEV && values.email === '' && values.password === '') {
        return {};
      }

      return formValidate(formSchema)(values);
    },
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => handleSubmit(values.email, values.password),
  });

  const { values, errors } = form;

  async function onResetPsw() {
    try {
      const email = formSchema.shape.email.parse(values.email);
      confirm({
        title: 'Reset password',
        content: tx.resetConfirm(values.email) + ' ' + txc.doYouWantToProceed,
        onMainAction: () => handleResetPsw(email),
      });
    } catch (_) {
      toast.warning('Please provide a valid email to request a password reset link');
      form.setFieldError('email', 'Please provide a valid email');
    }
  }

  return (
    <form className="w-full h-full mt-2" onSubmit={form.handleSubmit}>
      <div className="w-full relative">
        <input
          type="text"
          className="text-input h-12 bg-white mb-4"
          id="email"
          placeholder="Email"
          name="email"
          autoComplete="email"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={values.email}
          data-valid={!(form.touched.email && Boolean(errors.email))}
          autoFocus
          disabled={loading}
        />
      </div>

      <div className="w-full relative">
        <input
          className="text-input h-12 bg-white pr-14"
          name="password"
          placeholder="Password"
          type={showPassword ? 'text' : 'password'}
          id="password"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={values.password}
          data-valid={!(form.touched.password && Boolean(errors.password))}
          autoComplete="password"
          disabled={loading}
        />

        <TogglePasswordVisibilityButton
          visible={showPassword}
          onToggle={() => setShowPassword((v) => !v)}
        />
      </div>

      <div className="ml-1 mt-2.5">
        <button type="button" className="text-gray-400 hover:underline" onClick={onResetPsw}>
          {tx.forgotPassword}
        </button>
      </div>

      <Button
        className="mt-8 text-base w-full h-11 font-medium"
        type="submit"
        leftIcon={<MaterialIcon variant="outlined" icon="lock" style={{ fontSize: 16 }} />}
        isLoading={loading}
      >
        {tx.login}
      </Button>
    </form>
  );
};

const useTxs = createTxs({
  en: {
    login: 'Login',
    forgotPassword: 'Forgot password',
    resetConfirm: (email: string) =>
      `An email with a reset password link will be sent to ${email}.`,
  },
  it: {
    login: 'Entra',
    forgotPassword: 'Ho dimenticato la password',
    resetConfirm: (email) =>
      `Un link per eseguire il reset della password verrà inviato a ${email}.`,
  },
});

export default LoginForm;
