import { HeaderButton } from '@koolumbus/web/ui';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useRouter as useHistory } from 'next/router';
import React, { useEffect } from 'react';
import { fbStore } from '../../../services/firebase.service';
import { useAuthState } from '../../../state/auth';
import { useFireChatsStore } from '../../../state/fire-chats.store';
interface InboxButtonProps {}

const InboxButton: React.FC<React.PropsWithChildren<InboxButtonProps>> = () => {
  const history = useHistory();
  const { user } = useAuthState();

  const { chatsToRead, setChatsToRead } = useFireChatsStore();
  const hasUnreadMessages = chatsToRead.length > 0;

  useEffect(() => {
    let unsubscribe = () => {};

    if (user?.id) {
      const snapQuery = query(
        collection(fbStore, 'chats'),
        where('tenantId', '==', user.tenantId),
        where('tenantHasUnreadMessages', '==', true),
      );

      unsubscribe = onSnapshot(snapQuery, (snap) => {
        setChatsToRead(snap.docs.map((doc) => doc.id));
      });
    }

    return () => unsubscribe();
  }, [setChatsToRead, user?.id, user?.tenantId]);

  const onClick = () => {
    history.push(`/inbox`);
  };

  return (
    <HeaderButton
      icon="message"
      tooltip="Inbox"
      isFloating={false}
      onClick={onClick}
      hasRedDot={hasUnreadMessages}
    />
  );
};

export default InboxButton;
